@import "../../styles/breakpoints";

div.home-header {
  // background: pink;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include sm {
    flex-direction: row;
    margin-top: 40px;
  }

  .social {
    margin: auto 0;
  }
}

.name-header {
  display: flex;
  flex-direction: row;
}

.waving-hand {
  animation-name: wave;
  animation-duration: 0.45s;
  animation-iteration-count: 3;
  animation-timing-function: ease-in-out;
  margin-right: 10px;
}

.bio {
  max-width: 600px;
}

.social {
  > a {
    // font-size: 24px;
  }
  > a:first-child {
    margin-right: 3px;
  }
  > a:not(:first-child):not(:last-child) {
    margin: 0 3px;
  }
  > a:last-child {
    margin-left: 3px;
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
