// Extra small devices (portrait phones) do not need a media query.

// Small devices (landscape phones, etc.)
@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}

// Medium devices (tablets portrait mode)
@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

// Large devices (tablets landscape mode, desktops, etc.)
@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

// Extra
@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
